<template>
  <v-card
    :loading="isSearching"
    class="mt-0"
    elevation="16"
    :style="position"
    max-height="300"
    max-width="380"
    min-width="300"
  >
    <v-list
      v-if="results.length"
      max-height="300"
      class="overflow-y-auto"
    >
      <v-list-item
        v-for="result in results"
        :key="result.id"
        @click="goToProducts(result.id)"      
      >
        <v-list-item-avatar tile>
          <v-img :src="defaultImage(result.producto.imagenes[0])" />
        </v-list-item-avatar>
        <v-list-item-content>
          <!--<icons-dialog
            :isSearch="true"
            :prod="result"
            @clearText="clearSearch"
            @setClicked="setClicked"
          />-->
          {{ result.producto.descripcion_corta }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card v-else>
      <v-card-text v-if="!isSearching">
        No se encontraron coincidencias
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { inventoryMixin } from '@/mixins/inventory.js'
export default {
  components: {
    IconsDialog: () => import('../utils/IconsDialog.vue'),
  },
  mixins: [inventoryMixin],
  props: {
    results: {
      type: Array,
      default: () => []
    },
    isSearching: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    position() {
      return `z-index: 9999; position: absolute; top: 8rem;`
    },
  },
  methods: {
    ...mapActions('products', ['setIsSearch']),
    clearSearch() {
      this.$emit('clearText')
    },
    setClicked() {
      //console.log('Clicked desde item list');
      this.$emit('setClicked')
    },
    goToProducts(id) {
      this.setIsSearch(true)
      this.$router.push({
        name: 'Productos', 
        query: {
          filtro: 'P+' + id,
          id: id
        }
      })
    }
  }
}
</script>

<style>

</style>